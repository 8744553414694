import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import Builder from "../components/builder"

const OfferPage = ({ data }) => {
  const {
    builder_first,
    builder_second,
    builder_rodo,
    builder_alm,
    builder_bezpieczenstwo,
    builder_wsparcie,
  } = data.wordpressPage.acf

  const sectionFirstBox = builder_first.builder[1]
  const sectionFirstHeading = builder_first.builder[0]
  const sectionSecond = builder_second.builder[0]
  const sectionRodo = builder_rodo.builder
  const sectionAlm = builder_alm.builder
  const sectionBezpieczenstwo = builder_bezpieczenstwo.builder
  const sectionWspracie = builder_wsparcie.builder
  const { title, canonical } = data.wordpressPage.yoast

  const ScollToHash = e => {
    const clickedElement = e.currentTarget
    const regexHash = /#(\w*.)*/
    const hash = regexHash.exec(clickedElement.href)
    console.log(hash)
    if (hash && hash.length > 1) {
      e.preventDefault()
      const regexTitle = /=[A-z]*(%20)?[A-z]*/
      const element = document.querySelector(hash)
      let y = element.getBoundingClientRect().top + window.pageYOffset - 150
      window.scrollTo({ top: y, behavior: "smooth" })
    }
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        {canonical ? <link rel="canonical" href={canonical} /> : "Start - Dapr"}
        {/* {data.yoast.map(meta_value => {
          return (
            <meta
              name={meta_value.name || meta_value.property}
              content={meta_value.content}
            />
          )
        })} */}
      </Helmet>
      <Layout>
        <section className="background__white offer offer__white">
          <div className="container">
            <h1 className="heading--1">
              <Link
                to="#rodo"
                onClick={e => {
                  ScollToHash(e)
                }}
              >
                RODO
              </Link>
              <span>|</span>
              <Link
                to="#aml"
                onClick={e => {
                  ScollToHash(e)
                }}
              >
                AML
              </Link>
              <span>|</span>
              <Link
                to="#bezpieczenstwo-informacji"
                onClick={e => {
                  ScollToHash(e)
                }}
              >
                Bezpieczeństwo Informacji
              </Link>
              <span>|</span>
              <Link
                to="#wsparcie-it"
                onClick={e => {
                  ScollToHash(e)
                }}
              >
                Wsparcie IT
              </Link>
              <span>|</span>
              <Link
                to="#poznaj-nasze-szkolenia"
                onClick={e => {
                  ScollToHash(e)
                }}
              >
                Szkolenia
              </Link>
            </h1>
          </div>
          {sectionRodo.map(item => (
            <Builder
              loyaut={item.acf_fc_layout}
              text={item.text}
              type_heading={item.type_heading}
              data={item.row}
              startPosition={item.start_position}
            />
          ))}
        </section>

        <section className="background__dark offer offer__dark">
          <div className="container"></div>
          {sectionAlm.map(item => (
            <Builder
              loyaut={item.acf_fc_layout}
              text={item.text}
              type_heading={item.type_heading}
              data={item.row}
              startPosition={item.start_position}
            />
          ))}
        </section>

        <section className="background__white offer offer__white">
          <div className="container"></div>
          {sectionBezpieczenstwo.map(item => (
            <Builder
              loyaut={item.acf_fc_layout}
              text={item.text}
              type_heading={item.type_heading}
              data={item.row}
              startPosition={item.start_position}
            />
          ))}
        </section>

        <section className="background__dark offer offer__dark">
          <div className="container"></div>
          {sectionWspracie.map(item => (
            <Builder
              loyaut={item.acf_fc_layout}
              text={item.text}
              type_heading={item.type_heading}
              data={item.row}
              startPosition={item.start_position}
            />
          ))}
        </section>
        <div className="background__white offer offer__white ">
          <Builder
            loyaut={sectionFirstHeading.acf_fc_layout}
            text={sectionFirstHeading.text}
            type_heading={sectionFirstHeading.type_heading}
          />
        </div>
        <section className="background__white offer__white ">
          <Builder
            loyaut={sectionFirstBox.acf_fc_layout}
            left_text={sectionFirstBox.left_text}
            left_icon={sectionFirstBox.left_icon}
            left_background={sectionFirstBox.left_background}
            right_text={sectionFirstBox.right_text}
            right_icon={sectionFirstBox.right_icon}
            right_background={sectionFirstBox.right_background}
          />
        </section>
        <section className="background__dark offer__dark">
          <Builder
            loyaut={sectionSecond.acf_fc_layout}
            data={sectionSecond.row}
            startPosition={sectionSecond.start_position}
          />
        </section>
      </Layout>
    </>
  )
}

export default OfferPage

export const query = graphql`
  query OfferPage {
    wordpressPage(title: { eq: "oferta" }) {
      id
      yoast {
        title
        opengraph_description
        opengraph_image
        opengraph_title
        metakeywords
        metadesc
        canonical
        focuskw
        linkdex
        meta_robots_adv
        meta_robots_nofollow
        meta_robots_noindex
        redirect
        twitter_image
        twitter_title
        twitter_description
      }
      acf {
        builder_rodo {
          builder {
            acf_fc_layout
            text
            type_heading
            start_position
            row {
              button_color
              button_hover_color
              button_link
              button_text
              description
              subHeading
              heading
              img {
                source_url
                title
                localFile {
                  childImageSharp {
                    fixed(width: 860, quality: 90) {
                      srcSetWebp
                      srcWebp
                      srcSet
                      src
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
        builder_alm {
          builder {
            acf_fc_layout
            text
            type_heading
            start_position
            row {
              button_color
              button_hover_color
              button_link
              button_text
              description
              subHeading
              heading
              img {
                source_url
                title
                localFile {
                  childImageSharp {
                    fixed(width: 860, quality: 90) {
                      srcSetWebp
                      srcWebp
                      srcSet
                      src
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
        builder_bezpieczenstwo {
          builder {
            acf_fc_layout
            text
            type_heading
            start_position
            row {
              button_color
              button_hover_color
              button_link
              button_text
              description
              subHeading
              heading
              img {
                source_url
                title
                localFile {
                  childImageSharp {
                    fixed(width: 860, quality: 90) {
                      srcSetWebp
                      srcWebp
                      srcSet
                      src
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
        builder_wsparcie {
          builder {
            acf_fc_layout
            text
            type_heading
            start_position
            row {
              button_color
              button_hover_color
              button_link
              button_text
              description
              subHeading
              heading
              img {
                source_url
                title
                localFile {
                  childImageSharp {
                    fixed(width: 860, quality: 90) {
                      srcSetWebp
                      srcWebp
                      srcSet
                      src
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
        builder_first {
          builder {
            type_heading
            text
            acf_fc_layout
            left_text
            left_icon {
              source_url
            }
            left_background {
              localFile {
                childImageSharp {
                  fixed(width: 650, quality: 90) {
                    src
                    srcSet
                    srcSetWebp
                    srcWebp
                    width
                    height
                  }
                }
              }
            }
            right_text
            right_icon {
              source_url
            }
            right_background {
              localFile {
                childImageSharp {
                  fixed(width: 650, quality: 90) {
                    src
                    srcSet
                    srcSetWebp
                    srcWebp
                    width
                    height
                  }
                }
              }
            }
          }
        }
        builder_second {
          builder {
            acf_fc_layout
            start_position
            row {
              button_color
              button_hover_color
              button_link
              button_text
              description
              subHeading
              heading
              img {
                source_url
                title
                localFile {
                  childImageSharp {
                    fixed(width: 860, quality: 90) {
                      srcSetWebp
                      srcWebp
                      srcSet
                      src
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
